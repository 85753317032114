import { lazy, Suspense } from 'react';
import { Route, Routes } from 'react-router-dom';
import { BrowserRouter as Router } from 'react-router-dom';

const HomeScreen = lazy(() => import(/* webpackChunkName: "EndCallScreen" */ './screens/HomeScreen'));
const CarrerScreen = lazy(() => import(/* webpackChunkName: "EndCallScreen" */ './screens/CarrerScreen'));
const CarrerDetailScreen = lazy(() => import(/* webpackChunkName: "EndCallScreen" */ './screens/CarrerDetailScreen'));
const OpenSourceScreen = lazy(() => import(/* webpackChunkName: "EndCallScreen" */ './screens/OpenSourceScreen'));

function App() {
  return (
    <Router>
      <Routes>
        <Route path='/' element={
          <Suspense fallback={<>...</>}>
            <HomeScreen />
          </Suspense>
        } />

        <Route path='/carrer' element={
          <Suspense fallback={<>...</>}>
            <CarrerScreen />
          </Suspense>
        } />

        <Route path='/carrer/:id' element={
          <Suspense fallback={<>...</>}>
            <CarrerDetailScreen />
          </Suspense>
        } />

        <Route path='/contribution' element={
          <Suspense fallback={<>...</>}>
            <OpenSourceScreen />
          </Suspense>
        } />
      </Routes>
    </Router>
  );
}

export default App;

